import React from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import classes from "./App.module.css";
import profilePic from "./assets/ProfilePic.webp";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import picture1 from "./assets/Picture1.webp";
import picture2 from "./assets/Picture2.webp";
import picture3 from "./assets/Picture3.webp";
import picture4 from "./assets/Picture5.webp";
import picture6 from "./assets/Picture6.webp";
import picture7 from "./assets/Picture7.webp";
import picture8 from "./assets/Picture8.webp";
import picture9 from "./assets/Picture9.webp";
import picture10 from "./assets/Picture10.webp";
import banner from "./assets/Photo.webp";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

function App() {
  const smoothScrollTo = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Box className={classes.heroSection} id="home">
        <Box className={classes.overlay}></Box>
        <Box className={classes.navbarContainer}>
          <Box className={classes.navItem}>
            <a href="#" onClick={() => smoothScrollTo("home")}>
              Home
            </a>
            <a href="#" onClick={() => smoothScrollTo("books")}>
              Books
            </a>
            <a href="#" onClick={() => smoothScrollTo("aboutus")}>
              About
            </a>
            <a href="#" onClick={() => smoothScrollTo("gallery")}>
              Gallery
            </a>
            <Button
              variant="contained"
              target="_blank"
              size="small"
              className={classes.linkButton}
              href="https://www.amazon.com/s?i=stripbooks&rh=p_27%3ASara+Galadari&s=relevancerank&text=Sara+Galadari&ref=dp_byline_sr_book_1"
              sx={{
                backgroundColor: "FFCA42",
                color: "1B3764",
              }}
            >
              Order Today
            </Button>
          </Box>
        </Box>
        <Box className={classes.heroSectionHeading}>
          <ScrollAnimation animateIn="bounceInLeft">
            <Typography variant="h1">Sara Galadari</Typography>
          </ScrollAnimation>
        </Box>
      </Box>

      <Box className={classes.bookSectionWrapper} id="books">
        <ScrollAnimation animateIn="backInDown">
          <Typography variant="h4">Explore Sara's Novels</Typography>
        </ScrollAnimation>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInLeft">
            <Box className={classes.bookSection}>
              <img src={picture6} alt="City of Stars" />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">City of Stars</Typography>
                  <Typography variant="body1">
                    Raised in a world of beauty and wealth, Ameera Hussain was
                    everything that Majd Rayyan could ever dream of. She was
                    beautiful, smart, and an independent woman, and came from
                    one of the oldest, most well-respected family in Najmia.{" "}
                  </Typography>
                </Box>
                <Button
                  target="_blank"
                  href="https://www.amazon.com/City-Stars-Sara-Galadari-ebook/dp/B00D4ZKWE2/ref=mp_s_a_1_5?crid=2M5D4BSQ4M6AW&dib=eyJ2IjoiMSJ9.2-FNtNSA_Xq3ZtryyFntsRVXK2Vxq9DMikaF4zeMZU4fktOKwolWzW22co6ehC-yBW4P9AyI_dc_7L4LhON2rwKq0TW4WLFIA3DIhDNVaV4.xFMkJc7hbFO6xgXmOVZVYZWqtseZj8-9XJscP5cyNII&dib_tag=se&keywords=Sara+Galadari&qid=1715088265&sprefix=sara+galadari%2Caps%2C287&sr=8-5"
                  variant="outlined"
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInRight">
            <Box className={classes.bookSection}>
              <img src={picture7} alt="The Pigeon Chronicles" />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">The Pigeon Chronicles</Typography>
                  <Typography variant="body1">
                    Pigeons. Oh, how Bobby hated pigeons. In fact, he hated
                    pigeons so much that he would rather see them all go extinct
                    than have to live in the same world with one for another
                    minute.
                  </Typography>
                </Box>
                <Button
                  target="_blank"
                  href="https://www.amazon.com/Pigeon-Chronicles-Sara-Abdullatif-Galadari/dp/1508543224/ref=mp_s_a_1_2?crid=2M5D4BSQ4M6AW&dib=eyJ2IjoiMSJ9.2-FNtNSA_Xq3ZtryyFntsRVXK2Vxq9DMikaF4zeMZU4fktOKwolWzW22co6ehC-yBW4P9AyI_dc_7L4LhON2rwKq0TW4WLFIA3DIhDNVaV4.xFMkJc7hbFO6xgXmOVZVYZWqtseZj8-9XJscP5cyNII&dib_tag=se&keywords=Sara+Galadari&qid=1715088265&sprefix=sara+galadari%2Caps%2C287&sr=8-2"
                  variant="outlined"
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInLeft">
            <Box className={classes.bookSection}>
              <img src={picture8} alt="The Elemental" />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">The Elemental</Typography>
                  <Typography variant="body1">
                    The hunt for the Elemental flings an unexpected visitor into
                    Elara Jove’s life, throwing everything she had ever known
                    about her world into disarray. In a string of brutal attacks
                    and unexplained murders,
                  </Typography>
                </Box>
                <Button
                  target="_blank"
                  href="https://www.amazon.com/Elemental-Sara-Galadari/dp/B09BC8KS6D/ref=mp_s_a_1_4?crid=2M5D4BSQ4M6AW&dib=eyJ2IjoiMSJ9.2-FNtNSA_Xq3ZtryyFntsRVXK2Vxq9DMikaF4zeMZU4fktOKwolWzW22co6ehC-yBW4P9AyI_dc_7L4LhON2rwKq0TW4WLFIA3DIhDNVaV4.xFMkJc7hbFO6xgXmOVZVYZWqtseZj8-9XJscP5cyNII&dib_tag=se&keywords=Sara+Galadari&qid=1715088265&sprefix=sara+galadari%2Caps%2C287&sr=8-4"
                  variant="outlined"
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInRight">
            <Box className={classes.bookSection}>
              <img
                src={picture9}
                alt="What is Home – a bedtime story"
                className={classes.imageWidth}
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">
                    What is Home – a bedtime story
                  </Typography>
                  <Typography variant="body1">
                    All the wonderful things to behold, And all the marvelous
                    things to be told, A little family wants to explain, What is
                    home? Written by the best-selling author, Sara
                    Galadari, What is Home? 
                  </Typography>
                </Box>
                <Button
                  target="_blank"
                  href="https://www.amazon.com/What-Home-Sara-Galadari/dp/9948777913/ref=mp_s_a_1_1?crid=2M5D4BSQ4M6AW&dib=eyJ2IjoiMSJ9.2-FNtNSA_Xq3ZtryyFntsRVXK2Vxq9DMikaF4zeMZU4fktOKwolWzW22co6ehC-yBW4P9AyI_dc_7L4LhON2rwKq0TW4WLFIA3DIhDNVaV4.xFMkJc7hbFO6xgXmOVZVYZWqtseZj8-9XJscP5cyNII&dib_tag=se&keywords=Sara+Galadari&qid=1715088265&sprefix=sara+galadari%2Caps%2C287&sr=8-1"
                  variant="outlined"
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInUp">
            <Box className={classes.bookSection}>
              <img
                src={picture10}
                alt="As you Grow"
                className={classes.imageWidth}
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">As you Grow</Typography>
                  <Typography variant="body1">
                    There are so many places to wander to And so much to see as
                    you grow, Into the world's wonders and delights And in the
                    murky depths below.
                  </Typography>
                </Box>
                <Button
                  target="_blank"
                  href="https://www.amazon.com/As-You-Grow-Sara-Galadari/dp/B0CN69796Y/ref=mp_s_a_1_3?crid=2M5D4BSQ4M6AW&dib=eyJ2IjoiMSJ9.2-FNtNSA_Xq3ZtryyFntsRVXK2Vxq9DMikaF4zeMZU4fktOKwolWzW22co6ehC-yBW4P9AyI_dc_7L4LhON2rwKq0TW4WLFIA3DIhDNVaV4.xFMkJc7hbFO6xgXmOVZVYZWqtseZj8-9XJscP5cyNII&dib_tag=se&keywords=Sara+Galadari&qid=1715088265&sprefix=sara+galadari%2Caps%2C287&sr=8-3"
                  variant="outlined"
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
      </Box>

      <Grid
        container
        px={{ xs: 2, md: 5 }}
        py={{ xs: 3, md: 5 }}
        sx={{
          backgroundColor: "#1b3764",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className={classes.bannerOrder}
      >
        <Grid
          xs={12}
          sm={8}
          md={8}
          lg={8}
          mb={{ xs: 5, sm: 0 }}
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "36px", sm: "48px" },
            justifyContent: "center",
          }}
          className={classes.contentContainer}
        >
          <ScrollAnimation animateIn="backInLeft">
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",

                fontSize: { xs: "26px", md: "60px" },
              }}
            >
              Start reading
            </Typography>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInLeft">
            <Button
              variant="outlined"
              target="_blank"
              className={classes.linkButton}
              href="https://www.amazon.com/s?i=stripbooks&rh=p_27%3ASara+Galadari&s=relevancerank&text=Sara+Galadari&ref=dp_byline_sr_book_1"
              size="large"
              sx={{
                color: "white !important",
                border: "1px solid #ffca42",
                width: { xs: "100%", sm: "300px" },
              }}
            >
              Order Now
            </Button>
          </ScrollAnimation>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <img src={banner} alt="Banner" style={{ width: "100%" }} />
        </Grid>
      </Grid>

      <Box container className={classes.introSection} id="aboutus">
        <Box className={classes.authorPic}>
          <img src={profilePic} alt="Profile Pic" />{" "}
        </Box>
        <Box className={classes.textSection}>
          <Typography variant="h4">Sara Galadari</Typography>
          <Typography variant="body1">
            Sara Galadari is an Emirati best-selling author, having written
            numerous books for children and young adults. Bitten by a bookworm
            as a young child, she developed an unquenchable thirst for consuming
            every book she could get her hands on. Sara spent her youth visiting
            libraries and checking out dozens of books at a time (taking
            advantage of her brothers’ library and check out even more books for
            the week). After taking an interest in how language can shape
            meaning across culture, society, media, and cognitive processes, she
            went on to get her BA and MSc in Communication. Sara draws from her
            education to write stories that touch on pivotal topics, with the
            hopes of shaping bright minds to build a better tomorrow.
          </Typography>
          <br />
          <Box
            sx={{
              justifyContent: { xs: "center", md: "start" },
              display: "flex",
              gap: "8px",
            }}
          >
            <Link
              href="https://www.linkedin.com/in/saragaladari?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                width: "max-content",
              }}
            >
              <LinkedInIcon style={{ color: "#0077B5" }} />
            </Link>
            <Link
              href="https://www.instagram.com/saraagaladari?igsh=NmZ0aWhrY3dxM3l0"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                width: "max-content",
              }}
            >
              <InstagramIcon style={{ color: "#833AB4" }} />
            </Link>
          </Box>
        </Box>
      </Box>

      <Box
        className={classes.galleryContainer}
        px={{ xs: 2, md: 5 }}
        py={{ xs: 2, md: 5 }}
        id="gallery"
      >
        <ScrollAnimation animateIn="bounceInRight">
          <Typography variant="h4">Gallery</Typography>
        </ScrollAnimation>
        <WovenImageList />
      </Box>

      <Grid container px={{ xs: 0.5, sm: 2 }} className={classes.footer}>
        <Grid xs={12} sm={8} md={8} lg={8} item>
          <Typography variant="body1">
            Copyright © 2024 saragaladari.com - All Rights Reserved.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ justifyContent: "end", display: "flex", gap: "8px" }}
        >
          <Link
            href="https://www.linkedin.com/in/saragaladari?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "white",
              display: "flex",
              width: "max-content",
            }}
          >
            <LinkedInIcon style={{ color: "#0077B5" }} />
          </Link>
          <Link
            href="https://www.instagram.com/saraagaladari?igsh=NmZ0aWhrY3dxM3l0"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "white",
              display: "flex",
              width: "max-content",
            }}
          >
            <InstagramIcon style={{ color: "#833AB4" }} />
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default App;

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const WovenImageList = () => {
  return (
    <ImageList sx={{ height: 850 }} variant="quilted" cols={2} rowHeight={550}>
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const itemData = [
  {
    img: picture1,
    title: "Bed",
  },
  {
    img: picture2,
    title: "Kitchen",
  },
  {
    img: picture3,
    title: "Sink",
  },
  {
    img: picture4,
    title: "Books",
  },
];
